<template>
<b-form-datepicker
  v-model="getValue"
  v-bind="$attrs"
  :locale="getLocale"
  :label-no-date-selected="$t('DATEPICKER.NO_DATE_SELECTED')"
  class="datepicker-form form-control form-control-solid"
  @change="$emit('change', $event)"
  reset-button-variant=""
  :label-reset-button="$t('FORM.CLEAR')"
  :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
/>
</template>

<script>
export default {
  name: 'Datepicker',
  props: {
    value: null,
  },
  computed: {
    getLocale() {
      console.log(this.$attrs, 'this.$attrs')
      const loc = this.$i18n.locale
      return loc === 'ua' ? 'uk' : loc
    },
    getValue: {
      get() {
        return this.value ? this.$moment(this.value).format('YYYY-MM-DD') : ''
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
}
</script>

<style lang="scss">
  .datepicker-form {
    .is-invalid{
      background-image: none !important;
    }
    .is-valid{
      background-image: none !important;
    }
  }
</style>
