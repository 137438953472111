<template>
<KTCard>
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">{{ $t('SERVICES_PRICES.TITLE_NEW') }}</h5>
    </div>
  </template>
  <template #toolbar>
    <div class="example-tools justify-content-center">
      <router-link :to="{ name: 'servicesPricesIndex' }" class="btn btn-secondary font-weight-bold btn-sm">
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/Navigation/Angle-double-left.svg" />
        </span>
        {{ $t('BASE.BACK') }}
      </router-link>
    </div>
  </template>
  <template #body>
    <b-form class="form" @submit.stop.prevent="onSubmit">
      <b-container fluid class="p-0">
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group id="input-group-2" :label="`${$t('FORM.SERVICE')}:`" label-for="select-1">
              <SelectInfinityScroll
                id="select-1"
                v-model="$v.form.service.$model"
                :search.sync="searchService"
                :state="validateState('service') === false"
                api="company/services"
                clearable
                @input="clearServerError('service')"
                aria-describedby="select-1-feedback"
              />
              <b-form-invalid-feedback id="select-1-feedback" :class="{'d-block': validateState('service') === false}">
                <template v-if="serverErrors.parent">{{ serverErrors.service[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.SERVICE') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-1" :label="`${$t('FORM.COST')}:`" label-for="input-1">
              <InputForm
                id="input-1"
                v-model="$v.form.cost.$model"
                :state="validateState('cost')"
                :placeholder="$t('PLACEHOLDER.COST')"
                trim
                @input="clearServerError('name')"
                aria-describedby="input-1-feedback"
              />
              <b-form-invalid-feedback id="input-1-feedback">
                <template v-if="serverErrors.cost">{{ serverErrors.cost[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.COST') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group :label="`${$t('FORM.START_DATE')}:`" label-for="datepicker">
              <Datepicker id="datepicker"
                          v-model="$v.form.startDate.$model"
                          :state="validateState('startDate')"
                          @change="clearServerError('startDate')"
                          @input="form.endDate = null"
                          aria-describedby="datepicker-feedback"
              />
              <b-form-invalid-feedback id="datepicker-feedback">
                <template v-if="serverErrors.startDate">{{ serverErrors.startDate[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.START_DATE') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group :label="`${$t('FORM.END_DATE')}:`" label-for="datepicker-2">
              <Datepicker id="datepicker-2"
                          v-model="$v.form.endDate.$model"
                          :state="validateState('endDate')"
                          :min="form.startDate"
                          :disabled="!form.startDate"
                          @change="clearServerError('endDate')"
                          aria-describedby="datepicker-2-feedback"
              />
              <b-form-invalid-feedback id="datepicker-2-feedback">
                <template v-if="serverErrors.endDate">{{ serverErrors.endDate[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.START_DATE') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="text-right">
              <b-button :disabled="loading || $v.form.$anyError" type="submit" variant="primary" size="sm">
                <b-spinner v-if="loading" small variant="light" />
                {{ $t('FORM.SUBMIT') }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </template>
</KTCard>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Repo from '@/core/repository/company/servicePricesRepository'
import { TIMEOUT_REDIRECT } from '@/constants/base'
import KTCard from '../../../../content/Card'
import InputForm from '../../../../../components/forms-items/input'
import serverVuelidate from '../../../../../mixins/serverVuelidate'
import Datepicker from '../../../../../components/forms-items/Datepicker'
import SelectInfinityScroll from '../../../../../components/forms-items/SelectInfinityScroll'

export default {
  name: 'ServicesPricesCreate',
  components: {
    SelectInfinityScroll,
    Datepicker,
    InputForm,
    KTCard, 
  },
  mixins: [validationMixin, serverVuelidate],
  data() {
    return {
      loading: false,
      form: {
        cost: '',
        service: null,
        startDate: null,
        endDate: null,
      },
      searchService: '',
      validations: {
        form: {
          cost: { required },
          service: { required },
          startDate: { required },
          endDate: { required },
        },
      },
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('SERVICES_PRICES.TITLE_NEW') }])
  },
  methods: {
    resetForm() {
      this.form = {
        cost: '',
        service: null,
        startDate: null,
        endDate: null,
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.clearServerErrors()
      this.loading = true
      const obj = { ...this.form }
      obj.service = obj.service ? obj.service.uuid : obj.service
      Repo.post(obj).then(() => {
        this.$bvToast.toast(this.$t('TOAST.CREATED'), {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 2000,
          solid: true,
        })
        this.resetForm()
        setTimeout(() => this.$router.push({ name: 'servicesPricesIndex' }), TIMEOUT_REDIRECT)
        this.loading = false
      }).catch((err) => {
        this.loading = false
        this.seServerErrors(err.response.data)
      })
    },
  },
}
</script>

<style scoped>

</style>
