<template>
<b-dropdown :text="value ? value[textField] : ''" :disabled="disabled" ref="dropdown" class="dropdown-select" block>
  <template #button-content>
    <div class="d-flex justify-content-between align-items-center form-control form-control-solid" @click="onShow">
      <div class="d-flex align-items-center" :class="[{'form-control is-invalid px-0': state}]" style="overflow: hidden; width: calc(100% - 20px)">
        <div v-if="value && (value && value.length && multiple) || (value && !multiple)" class="d-flex justify-content-between flex-nowrap">
          <div class="d-flex align-center" style="">
            <template v-if="multiple">
              <div v-for="item in value" :key="item.uuid" class="btn btn-secondary btn-sm py-1 mr-1">
                <span @click.stop="removeItem(item)" class="svg-icon">
                  <inline-svg src="/media/svg/icons/Navigation/Close.svg" />
                </span>
                {{ item[textField] }}
              </div>
            </template>
            <template v-else>
              <slot name="selected" :selected="value">
                {{ value[textField] }}
              </slot>
            </template>
          </div>
        </div>
        <span v-else class="text-muted">{{ placeholder || $t('PLACEHOLDER.SELECT') }}</span>
      </div>
      <div class="d-flex">
        <div
          v-if="clearable && (value && value.length && multiple) || (value && !multiple)"
          @click.stop="$emit('input', null)"
          class="svg-icon svg-icon-secondary svg-icon-md d-flex align-center cursor-pointer mr-3"
        >
          <inline-svg src="/media/svg/icons/Navigation/Close.svg" />
        </div>
        <span class="svg-icon svg-icon-md mr-0">
          <inline-svg src="/media/svg/icons/Navigation/Angle-down.svg" />
        </span>
      </div>
    </div>
  </template>
  <b-dropdown-header>
    <b-form-input
      v-model="searchtext"
      trim
      ref="searchtext"
      type="text"
      debounce="500"
      class="form-control-solid"
      :placeholder="$t('BASE.SEARCH')"
    />
  </b-dropdown-header>
  <div class="scroll-div scrollbar-primary" v-infinite-scroll="loadmore" infinite-scroll-distance="10">
    <div class="force-overflow">
      <b-dropdown-item
        v-for="option in filteredList"
        :key="option[valueField]"
        :active="isActiveClass(option[valueField])"
        @click.native.prevent.capture="selectme($event, option)"
      >
        <span class="ml-4">
          <slot name="option" :option="option">
            {{ option[textField] }}
          </slot>
        </span>
      </b-dropdown-item>
      <b-dropdown-item v-if="loading === true" class="text-center">
        <inline-svg src="/media/svg/icons/Text/Dots.svg" />
      </b-dropdown-item>
      <template v-if="!options.length && page">
        <template v-if="!!$slots.isEmpty">
          <slot name="isEmpty" />
        </template>
        <div v-else class="text-center text-muted py-3">
          <span class="text-muted">{{ $t('BASE.NO_DATA') }}</span>
        </div>
      </template>
    </div>
  </div>
</b-dropdown>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll'

export default {
  name: 'SelectInfinityScroll',
  directives: { infiniteScroll },
  model: {
    event: 'input',
    prop: 'value',
  },
  props: {
    value: null,
    placeholder: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
    },
    api: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    valueField: {
      type: String,
      default: 'uuid',
    },
    textField: {
      type: String,
      default: 'name',
    },
    params: null,
    search: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
    },
    state: {
      type: Boolean,
    },
    multiple: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      more: true,
      options: this.items,
      page: 0,
      limit: 20,
    }
  },
  computed: {
    searchtext: {
      get() {
        return this.search
      },
      set(v) {
        this.$emit('update:search', v)
      },
    },
    filteredList() {
      if (this.api) return this.options
      return this.options.filter((item) => {
        return item[this.textField].toLowerCase().includes(this.searchtext.toLowerCase())
      })
    },
    isActiveClass() {
      return (valueField) => {
        if (this.multiple) {
          return this.value && this.value.map((m) => m[this.valueField]).includes(valueField)
        }
        return (this.value && this.value[this.valueField]) === valueField
      }
    },
  },
  watch: {
    searchtext() {
      this.page = 1
      this.more = true
      this.loadmore(true)
    },
  },
  methods: {
    onHide() {
      // use for close outside component
      this.$refs.dropdown.hide(true)
      this.$emit('input', null)
      this.searchtext = ''
    },
    onShow() {
      if (!this.page) {
        this.page = 1
        this.loadmore()
      }
      setTimeout(() => this.$refs.searchtext.focus(), 100)
    },
    removeItem(item) {
      this.toggleItem(item)
    },
    toggleItem(item) {
      let res = null
      if (this.value.map((m) => m[this.valueField]).includes(item[this.valueField])) {
        const index = this.value.findIndex((f) => f[this.valueField] === item[this.valueField])
        res = this.value.slice().splice(index, 1)
        // eslint-disable-next-line vue/no-mutating-props
        this.value.splice(index, 1) // TODO: Mutate prop
        if (!this.value.length) this.$emit('input', null)
      } else {
        res = this.value.concat(item)
        this.$emit('input', res)
      }
    },
    selectme(event, record) {
      if (this.multiple) {
        event.stopPropagation()
        if (!this.value) this.$emit('input', [record])
        else this.toggleItem(record)
      } else {
        this.$emit('input', record)
      }
    },
    loadmore(fresh) {
      if (!this.api || !this.page || !this.more || this.loading) return
      this.loading = true
      this.fetchData({
        done: (data) => {
          if (fresh) {
            this.options = data
          } else {
            data.forEach((x) => {
              this.options.push(x)
            })
          }
          this.loading = false
        },
        q: this.searchtext,
        page: this.page,
      })
    },
    apimethod(search, page) {
      // 'a.b.etc'.split('.').reduce((o,i)=>o[i], obj)
      let params = {
        pagination: {
          page: page || 1,
          limit: this.limit,
        },
      }
      if (this.params) {
        params = {
          ...params,
          ...this.params,
        }
      } else {
        params = {
          ...params,
          filter: { [this.textField]: search },
        }
      }
      return new Promise((resolve) => {
        this.$http.get(this.api, params).then(({ data }) => {
          this.more = data.payload.pagination.current < data.payload.pagination.last
          if (this.more) this.page += 1
          resolve(data.payload.items)
        })
      })
    },
    fetchData(params) {
      this.apimethod(params.q, params.page).then((options) => {
        params.done(options)
      })
    },
  },
}
</script>
<style lang="scss">
  .dropdown-select{
    width: 100%;
    padding: 0;
    .form-control.is-invalid{
      background-color: transparent;
      border: none;
    }
    .dropdown-toggle::after {
      display: none;
    }
    .dropdown-menu{
      width: 100%;
      max-height: 200px;
    }
    .btn-block {
      border: none;
      padding: 0;
    }
  }

</style>
<style lang="scss" scoped>
  .scroll-div{
    min-height: 40px; overflow-y:auto;
    max-height: 140px;
    width: 100%;
    margin: 0 auto;
  }
</style>
