export default {
  data() {
    return {
      validations: {
        form: {},
      },
      serverErrors: {},
    }
  },
  validations() {
    return this.rules
  },
  computed: {
    rules() {
      return { ...this.validations }
    },
  },
  methods: {
    clearServerError(key, validationKey = 'form') {
      if (this.validations[validationKey][key] && this.validations[validationKey][key].serverErrors) {
        this.$delete(this.validations[validationKey][key], 'serverErrors')
      }
    },
    clearServerErrors(validationKey = 'form') {
      this.serverErrors = {}
      Object.keys(this.validations[validationKey]).forEach((key) => {
        if (this.validations[validationKey][key].serverErrors) this.$delete(this.validations[validationKey][key], 'serverErrors')
      })
    },
    seServerErrors(data, validationKey = 'form') {
      if (data.code && +data.code === 400 && Object.keys(data.errors).length) {
        this.serverErrors = data.errors
        Object.entries(data.errors).forEach(([key, val]) => {
          this.$set(this.validations[validationKey][key], 'serverErrors', () => !val)
        })
      }
    },
  },
}
